<template>
  <v-card light :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>
        Issue an Incentive
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center pb-5" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-stepper v-if="!loading" v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :editable="step > 1 && !approval" step="1">
            <v-badge dot color="error" v-if="!valid.step1"> {{ $i18n.translate("Serial Number") }} Lookup </v-badge>
            <span v-else>{{ $i18n.translate("Serial Number") }} Lookup</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="(step > 2 || valid.step1) && !approval && sale != null" step="2">
            <v-badge dot color="error" v-if="sale != null && !valid.step2">
              Select Sale
            </v-badge>
            <span v-else>Select Sale</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :editable="step > 3 && !approval && selectedSales && selectedSales.length == 1 && sale != null"
            step="3"
          >
            <v-badge
              dot
              color="error"
              v-if="step > 2 && !approval && selectedSales && selectedSales.length == 1 && sale != null && !valid.step3"
            >
              Incentive Details
            </v-badge>
            <span v-else>Incentive Details</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="(step > 4 || (valid.step1 && valid.step2 && valid.step3)) && !approval" step="4">
            <span>Complete</span>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="vinOrHinLookupForm"
              @submit.prevent="onSubmit_vinOrHinLookup"
              v-if="!loading"
              v-model="valid.step1"
            >
              <v-container>
                <v-row>
                  <v-col lg="8" md="8" sm="10" xs="12">
                    <h3>Lookup Existing Sale</h3>
                    <p>
                      To start an incentive and approval process, start by looking up a sale by
                      {{ $i18n.translate("Serial Number") }}.
                    </p>
                    <v-text-field
                      maxLength="17"
                      :label="$i18n.translate('Serial Number')"
                      v-model="form_vinOrHinLookup.serialNumber"
                      :rules="rules_vinOrHinLookup.serialNumber"
                      class="required"
                    ></v-text-field>
                    <v-radio-group v-model="form_vinOrHinLookup.assignment" :rules="rules_vinOrHinLookup.assignment">
                      <template v-slot:label>
                        <label
                          >Is this incentive for a Racer or Salesperson / Dealer?<span class="red--text">*</span></label
                        >
                      </template>
                      <v-radio label="Racer" value="RACER"></v-radio>
                      <v-radio label="Salesperson / Dealer" value="SALESPERSON_DEALER"></v-radio>
                    </v-radio-group>
                    <v-btn :loading="loading" color="primary" :disabled="!valid.step1" @click="onSubmit_vinOrHinLookup">
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form ref="selectSaleForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step2">
              <v-container>
                <v-row>
                  <v-col>
                    <h3>More than one Sale was found</h3>
                    <p>Choose an existing sale to create an incentive for.</p>
                    <v-data-table
                      v-if="sales && sales.length > 0"
                      :headers="saleHeaders"
                      :items="sales"
                      show-select
                      single-select
                      v-model="selectedSales"
                      hide-default-footer
                    >
                      <template v-slot:item.saleKey="{ item }">
                        <router-link :to="{ name: 'sale', params: { id: item.id } }">
                          {{ item.saleKey }}
                        </router-link>
                      </template>
                      <template v-slot:item.productRegistrationDate="{ item }">
                        {{ item.customFieldValue10 | customField(selectedProgram.saleCustomFields[9]) }}
                      </template>
                      <template v-slot:item.saleType="{ item }">
                        {{ item.saleType.name }}
                      </template>
                    </v-data-table>
                    <br /><br />
                    <v-btn text @click="step--">{{ $i18n.translate("Previous") }}</v-btn>
                    <v-btn
                      :loading="loading"
                      class="ml-2"
                      color="primary"
                      :disabled="selectedSales.length != 1"
                      @click="onSubmit_selectSale"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="payoutForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step3">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <ApprovalSidebar
                      v-if="sale"
                      :approvalType="approvalType"
                      :sale="sale"
                      :saleType="saleType"
                      :saleProducts="saleProducts"
                      :saleParticipant="sale.participant"
                      :saleOrganization="sale.organization"
                    />
                  </v-col>
                  <v-col cols="8">
                    <p>
                      Based on the Sale information found by {{ $i18n.translate("Serial Number") }}
                      {{ saleProduct ? saleProduct.serialNumber : "" }}, enter payout information to create an
                      Incentive.
                    </p>
                    <PromotionSimpleField
                      label="Select a Promotion"
                      :showKey="true"
                      v-model="selectedPromotion"
                      :status="'All'"
                      :rules="rules_payout.promotion"
                      class="required"
                    />
                    <v-text-field
                      name="approvalName"
                      label="Name"
                      id="approvalName"
                      v-model="form_payout.approvalName"
                      :disabled="!isEditing"
                    />
                    <v-text-field
                      name="payoutAmount"
                      label="Payout Amount"
                      id="payoutAmount"
                      type="number"
                      min="0"
                      max="99999"
                      :rules="rules_payout.payoutAmount"
                      v-model="form_payout.payoutAmount"
                      :disabled="!isEditing"
                      :error-messages="$error.getValidationError(errors, 'payoutAmount')"
                      @input="$error.clearValidationError(errors, 'payoutAmount')"
                      class="required"
                    />
                    <AwardVehicleField
                      v-if="selectedPromotion"
                      :readonly="this.selectedPromotion.forcedAwardVehicle != null"
                      :clearable="this.selectedPromotion.forcedAwardVehicle == null"
                      v-model="selectedAwardVehicle"
                      :label="$i18n.translate('Award Vehicle')"
                      :rules="rules_payout.awardVehicle"
                      :available="availableAwardVehicles"
                      class="required"
                    ></AwardVehicleField>

                    <OrganizationField
                      v-if="selectedPromotion && promotionPaysToOrganization"
                      v-model="selectedOrganization"
                      :rules="rules_payout.organization"
                      :isAutoComplete="true"
                      :showCityRegionPostalCode="true"
                      class="required"
                      showKey
                      :label="'Payout Assignment for ' + $i18n.translate('Organization')"
                    ></OrganizationField>

                    <ParticipantField
                      v-else-if="selectedPromotion"
                      v-model="selectedParticipant"
                      :rules="rules_payout.participant"
                      :participantType="selectedParticipantType"
                      class="required"
                      showKey
                      showParticipantType
                      :label="'Payout Assignment for ' + $i18n.translate('Participant')"
                    ></ParticipantField>

                    <v-textarea name="comment" label="Comment" id="comment" v-model="form_payout.comment" />
                    <UploadField
                      label="Supporting Documentation"
                      :limitNumberFile="1"
                      v-model="selectedUpload"
                    ></UploadField>
                    <v-btn text @click="step--">{{ $i18n.translate("Previous") }}</v-btn>
                    <v-btn
                      :loading="loading"
                      class="ml-2"
                      color="primary"
                      :disabled="!valid.step3"
                      @click="onSubmit_payout"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    v-if="sale"
                    :approval="approval"
                    :payout="payout"
                    :approvalType="approvalType"
                    :sale="sale"
                    :saleType="saleType"
                    :saleProducts="saleProducts"
                    :saleParticipant="sale.participant"
                    :saleOrganization="sale.organization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2>Success!</h2>
                  <p class="mt-3 mb-10">
                    An incentive <b>payout</b> and <b>approval</b> has been created. Once this incentive payout has been
                    approved, it will be paid.
                  </p>
                  <v-btn :loading="loading" color="primary" @click="onStartOver">
                    Issue another Incentive
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import AwardVehicleField from "../../gapp-components/components/fields/AwardVehicleField.vue";
import OrganizationField from "../../gapp-components/components/fields/OrganizationField.vue";
import ParticipantField from "../../gapp-components/components/fields/ParticipantField.vue";
import PromotionSimpleField from "../../gapp-components/components/fields/PromotionSimpleField.vue";
import UploadField from "../../gapp-components/components/fields/UploadField.vue";
import ApprovalSidebar from "../../gapp-components/components/views/promotions/ApprovalSidebar.vue";

export default {
  components: {
    PromotionSimpleField,
    AwardVehicleField,
    ParticipantField,
    UploadField,
    ApprovalSidebar,
    OrganizationField,
    ApiError
  },
  name: "Incentive",
  metaInfo: {
    title: "Incentive"
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false
    },
    loading: false,
    isEditing: true,
    errors: {},
    saleType: null,
    sales: [],
    sale: null,
    saleProducts: [],
    saleProduct: null,
    approvalType: null,
    approval: null,
    payout: null,

    saleHeaders: [
      {
        value: "saleKey",
        text: "Sale Key",
        sortable: true
      },
      {
        value: "productRegistrationDate",
        text: "Product Registration Date",
        sortable: true
      },
      {
        value: "saleType",
        text: "Sale Type",
        sortable: true
      }
    ],

    selectedParticipantType: null,
    selectedPromotion: null,
    selectedParticipant: null,
    selectedOrganization: null,
    selectedUpload: {
      existing: [],
      deleted: []
    },
    selectedSales: [],
    selectedAwardVehicle: null,
    availableAwardVehicles: [],

    form_vinOrHinLookup: {
      serialNumber: ""
    },
    form_payout: {
      payoutAmount: ""
    },
    rules_vinOrHinLookup: {
      serialNumber: [
        v =>
          (!!v && (v.trim().length == 12 || v.trim().length == 15 || v.trim().length == 17)) ||
          "A valid VIN or HIN is required."
      ],
      assignment: [v => !!v || "Assignment is required."]
    },
    rules_payout: {
      awardVehicle: [v => !!v || "An award vehicle is required."],
      promotion: [v => (!!v && v.id > 0) || "A promotion is required."],
      payoutAmount: [v => !!v || "A payout amount is required."],
      participant: [v => (!!v && v.id > 0) || "A payout assignment is required."],
      organization: [v => (!!v && v.id > 0) || "A payout assignment is required."]
    }
  }),
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedLocale"]),
    validAll() {
      return this.valid.step1 && this.valid.step2 && this.valid.step3;
    },
    promotionPaysToOrganization() {
      if (this.selectedPromotion && this.selectedPromotion.payoutStrategyType) {
        switch (this.selectedPromotion.payoutStrategyType.name) {
          case "PAY_TO_ORGANIZATION":
          case "PAY_TO_ORGANIZATION_SPECIFIC":
          case "PAY_TO_ORGANIZATION_PARENT":
          case "PAY_TO_PARTICIPANT_ORGANIZATION":
          case "PAY_TO_PARTICIPANT_ORGANIZATION_PARENT":
            return true;
          default:
            return false;
        }
      }
      return false;
    }
  },
  watch: {
    selectedPromotion: {
      handler() {
        if (!this.selectedPromotion.name) {
          return;
        }

        this.fetchAvailableAwardVehicles();

        if (this.promotionPaysToOrganization) {
          this.form_payout.approvalName =
            "Incentive on " +
            this.saleProduct.serialNumber +
            " for " +
            this.selectedOrganization.name +
            " on " +
            this.selectedPromotion.name;
        } else if (this.saleProduct && this.selectedParticipant && this.selectedPromotion) {
          this.form_payout.approvalName =
            "Incentive on " +
            this.saleProduct.serialNumber +
            " for " +
            this.selectedParticipant.fullName +
            " on " +
            this.selectedPromotion.name;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.fetchApprovalType("KMC_CUSTOM_INCENTIVE");
  },
  methods: {
    onStartOver() {
      this.step = 1;
      this.selectedPromotion = null;
      this.selectedUpload = {
        existing: [],
        deleted: []
      };
      this.selectedAwardVehicle = null;
      this.selectedParticipant = null;
      this.selectedOrganization = null;
      this.form_vinOrHinLookup = {
        serialNumber: ""
      };
      this.form_payout = {
        payoutAmount: ""
      };
    },
    fetchApprovalType(approvalTypeKey) {
      return this.$api
        .post("/api/approvalTypes/search?size=1", { approvalTypeKey: approvalTypeKey })
        .then(({ data }) => {
          if (data && data.content && data.content.length == 1) {
            this.approvalType = data.content[0];
          } else {
            return Promise.reject("Unable to find approval type by key: " + approvalTypeKey);
          }
        });
    },
    onSubmit_vinOrHinLookup() {
      if (!this.valid.step1) {
        return;
      }
      this.form_vinOrHinLookup.serialNumber = this.form_vinOrHinLookup.serialNumber.trim();
      this.errors = {};
      this.loading = true;

      let saleTypePromise = Promise.resolve();
      if (this.form_vinOrHinLookup.assignment == "RACER") {
        saleTypePromise = this.fetchSaleTypeBySaleTypeKey("R");
      }
      return saleTypePromise
        .then(saleType => {
          this.saleType = saleType;

          let saleFilters = {
            serialNumber: this.form_vinOrHinLookup.serialNumber
          };
          if (this.saleType && this.saleType.id) {
            saleFilters.saleType = { id: this.saleType.id };
          }
          return this.$api.post("/api/sales/search", saleFilters).then(({ data }) => {
            if (data && data.content) {
              this.sales = data.content;
              if (this.sales.length == 0) {
                return Promise.reject("Unable to find existing sale by " + this.$i18n.translate("Serial Number"));
              } else if (this.sales.length == 1) {
                this.sale = this.sales[0];
                this.selectedSales = [this.sale];
                return this.fetchParticipantType().then(() => {
                  return this.populateSale(this.sale).then(() => {
                    this.step = 3;
                    if (this.$refs.payoutForm) {
                      this.$refs.payoutForm.validate();
                    }
                  });
                });
              } else {
                this.step = 2;
                return Promise.resolve();
              }
            } else {
              return Promise.reject("Unable to find existing sale by " + this.$i18n.translate("Serial Number"));
            }
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchParticipantType() {
      return this.$api
        .post("/api/participantTypes/search?size=2", {
          participantTypeKeys: [this.form_vinOrHinLookup.assignment == "RACER" ? "400" : "300"],
          isEffective: "Active"
        })
        .then(({ data }) => {
          if (data && data.content && data.content.length == 2) {
            this.selectedParticipantType = data.content;
            return Promise.resolve(this.selectedParticipantType);
          } else {
            this.selectedParticipantType = null;
            return Promise.reject("Unable to find Sale Type of 'R' (Initial Registration)");
          }
        });
    },
    fetchSaleTypeBySaleTypeKey(saleTypeKey) {
      return this.$api
        .post("/api/saleTypes/search?size=1", { saleTypeKey: saleTypeKey, isEffective: "Active" })
        .then(({ data }) => {
          if (data && data.content && data.content.length == 1) {
            return Promise.resolve(data.content[0]);
          } else {
            return Promise.reject("Unable to find Sale Type of 'R' (Initial Registration)");
          }
        });
    },
    populateSale(sale) {
      if (this.form_vinOrHinLookup.assignment == "RACER") {
        this.selectedParticipant = null;
      } else {
        this.selectedParticipant = sale.participant;
      }
      this.selectedOrganization = sale.organization;
      return this.$api.get("/api/saleProducts/bySaleId/" + sale.id + "?size=1").then(({ data }) => {
        this.saleProducts = data.content;
        if (this.saleProducts && this.saleProducts.length > 0) {
          this.saleProduct = this.saleProducts[0];
          return Promise.resolve();
        } else {
          return Promise.reject("Unable to find sale product for Sale " + sale.saleKey);
        }
      });
    },
    fetchAvailableAwardVehicles() {
      this.selectedAwardVehicle = null;
      if (this.selectedPromotion) {
        return this.$api
          .get("/api/promotionTypes/" + this.selectedPromotion.promotionType.id + "/availableAwardVehicles")
          .then(({ data }) => {
            this.availableAwardVehicles = data._embedded.awardVehicles;

            if (this.selectedPromotion.forcedAwardVehicle) {
              this.selectedAwardVehicle = this.selectedPromotion.forcedAwardVehicle;
            }
          });
      } else {
        return Promise.resolve();
      }
    },
    onSubmit_selectSale() {
      this.loading = true;
      this.sale = this.selectedSales[0];
      this.populateSale(this.sale)
        .then(() => {
          this.step++;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit_payout() {
      this.errors = {};
      this.loading = true;

      return this.createPayout()
        .then(() => {
          this.step++;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createPayout() {
      let payout = {
        awardVehicleDetermination: { name: "DETERMINED_BY_MANUAL" },
        awardVehicle: { id: this.selectedAwardVehicle.id },
        currency: { name: "USD" },
        promotion: { id: this.selectedPromotion.id },
        payoutStrategyType: { name: this.selectedPromotion?.payoutStrategyType?.name },
        sale: { id: this.sale.id },
        saleProduct: { id: this.saleProduct.id },
        payoutAmount: parseFloat(this.form_payout.payoutAmount),
        payoutType: { name: "MONEY" },
        payoutMessage: this.form_payout.approvalName
      };
      if (this.promotionPaysToOrganization && this.selectedOrganization) {
        payout.organization = { id: this.selectedOrganization.id };
      } else if (this.selectedParticipant) {
        payout.participant = { id: this.selectedParticipant.id };
      } else {
        return Promise.reject("Assignment of incentive is missing");
      }

      return this.$api.post("/api/payouts/create", payout).then(({ data }) => {
        this.payout = data;
        return this.createApproval(data);
      });
    },
    saveApprovalUpload(approval) {
      let postForm = { id: approval.id };
      if (this.selectedUpload.existing) {
        postForm.uploads = this.selectedUpload.existing.map(upload => this.$api.getSelfUrl("uploads", upload));
        return this.$api.patch("/api/approvals/" + approval.id, postForm);
      } else {
        return Promise.resolve();
      }
    },
    createApproval(payout) {
      let approval = {
        payout: { id: payout.id },
        name: this.form_payout.approvalName,
        approvalType: { id: this.approvalType.id }
      };
      return this.$api.post("/api/approvals/create", approval).then(({ data }) => {
        this.approval = data;
        return this.createApprovalNote(data).then(() => {
          return this.saveApprovalUpload(this.approval);
        });
      });
    },
    createApprovalNote(approval) {
      if (this.form_payout.comment && this.form_payout.comment.trim().length > 0) {
        let approvalNote = {
          approval: { id: approval.id },
          note: this.form_payout.comment
        };
        return this.$api.post("/api/approvalNotes", approvalNote);
      } else {
        return Promise.resolve();
      }
    }
  }
};
</script>
